* {
  margin: 0;
  padding: 0;
}

html,
body {
  /*overflow: hidden;*/
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

@import url("https://use.typekit.net/jmk3xov.css");

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

:root {
  --dark-bg: rgba(15, 15, 15, 0.95);
  --spacing: 50px;

  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.audioT {
  z-index: 99;
}

main {
  width: 100vw;
  color: white;
  z-index: 99;
  width: 100%;
  margin: 0px auto;
  padding: 120px 0px;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

h1,
h2,
h3,
blockquote {
  font-family: elevon, sans-serif;
  font-weight: 700;
  font-style: normal;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

header {
  background: var(--dark-bg);
  border-radius: 10px;
  grid-column: 2 / span 5;
  font-size: 2.5rem;
  padding: 1rem;
  margin-bottom: var(--spacing);
}

section {
  grid-column: 2 / 8;
  padding: 1.5rem 2.5rem;
  background: var(--dark-bg);
  border-radius: 10px;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: var(--spacing);
}

blockquote {
  margin: 0;
  padding: 0;
  grid-column: 2 / span 9;
  margin-bottom: var(--spacing);
}

blockquote p {
  color: black;
  background-color: white;
  font-size: 4rem;
  display: inline;
  line-height: 1;
}

.left {
  grid-column: 6 / 12;
}

.myButton {
  border: 1px solid rgb(7, 238, 18);
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  color: rgb(7, 238, 18);
  font-family: Arial;
  font-size: 13px;
  margin: 0.5rem 0;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #854629;
}
.myButton:hover {
  box-shadow: 0px 0px 5px 5px rgb(7, 238, 18);
}
.myButton:active {
  position: relative;
  top: 1px;
}

.title {
  text-align: center;
}
.inline-text {
  display: inline;
}

.socials {
  color: white;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.rowFlex {
  display: flex;
}

.imgLeft {
  text-align: center;
  flex: 50%;
  padding: 5px;
}

.imgRight {
  text-align: center;
  flex: 50%;
  padding: 0px;
}

.imageContainerVert {
  background-color: rgba(15, 15, 15, 0);
  text-align: center;
}

.imageHoverVertical {
  background-color: rgba(15, 15, 15, 0.95);
  margin: 0;
  padding: 2%;
  position: absolute;
  width: 20vw;
  height: 80vh;
  z-index: 9999;
}

.imageHoverHorizontal {
  background-color: rgba(15, 15, 15, 0.95);
  margin: 0;
  padding: 2%;
  position: absolute;
  width: 50vw;
  height: 50vh;
  z-index: 9999;
}

.imageContainer {
  background-color: rgba(15, 15, 15, 0);
  text-align: center;
}

/* .imageContainer > img:hover {
  background-color: rgba(15, 15, 15, 0.95);
  margin: 0;
  padding: 2%;
  position: absolute;
  left: 10vw;
  width: 50vw;
  height: 50vh;
  z-index: 9999;
} */

.imageNonExpand {
  text-align: center;
}

.listLeft {
  float: left;
  width: 50%;
}

.listRight {
  float: right;
  width: 50%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}
.video {
  text-align: center;
  margin: 0 auto;
}

.audioButton {
  padding: 10px;
  position: absolute;
  top: 0;
}

.buttonControls {
  margin: 1rem 0;
}

.bullet-points-main {
  padding: 1rem 4rem;
}

ul,
ol {
  list-style-position: inside;
}

.hide {
  display: none !important;
}

.link {
  color: #e30000;
}

.credits {
  box-shadow: 0px 0px 5px 5px red;
}

/* Custom Scrollbar*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #171a21;
}

::-webkit-scrollbar-thumb {
  background: #1b2838;
}

::-webkit-scrollbar-thumb:hover {
  background: #2a475e;
}
